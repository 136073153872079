<template>
  <div class="commercial-list__wrapper">
    <b-table
      ref="selectableTable"
      :fields="[
        { key: 'id', label: 'ID' },
        { key: 'name', label: $t('table.name') },
        { key: 'duration', label: $t('table.durationShort') },
        { key: 'version', label: $t('table.version') },
        { key: 'selected', label: $t('table.position') },
      ]"
      :items="commercialItems"
      select-mode="single"
      :selectable="canCreate && !isDisabled"
      class="table-sm small commercials-table"
      :table-class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'"
      @row-selected="onCommRowSelected"
    >
      <template #head(name)="data">
        <div class="resizable-div__name">
          {{ data.label }}
        </div>
      </template>
      <template #cell(name)="data">
        <span :class="[data.item.double_spot_id ? 'ds-option' : '', data.item.couple ? 'couple' : '']">
          {{ data.item.name }}
          <CoBrandBadge v-if="data.item.co_brand_id != null" />
        </span>
      </template>
      <!-- Select with options for last column -->
      <template #cell(selected)="data">
        <b-form-select
          v-if="
            data.item.double_spot_id
              ? data.index === commercialItems.indexOf(commercialItems.find((el) => el.double_spot_id === data.item.double_spot_id))
              : true
          "
          v-model="data.item.selected"
          size="sm"
          :options="premiumPositionOptions"
          class="position-select"
          :disabled="!canCreate || isDisabled"
          @input="selectTableRow(data.index)"
        ></b-form-select>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    BTable,
    CoBrandBadge: () => import('@/components/CoBrandBadge.vue'),
  },
  model: {
    prop: 'selectedMediaPlanCommercial',
    event: 'onCommercialSelect',
  },
  props: {
    selectedMediaPlanCommercial: {
      type: Object,
      default: undefined,
    },
    mediaPlanCommercials: {
      required: true,
      type: Array,
    },
    isDisabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      premiumPositionOptions: [
        { text: '1F', value: '1F' },
        { text: '2F', value: '2F' },
        { text: '3F', value: '3F' },
        { text: '--', value: null },
        { text: '3L', value: '3L' },
        { text: '2L', value: '2L' },
        { text: '1L', value: '1L' },
      ],
    };
  },
  computed: {
    selectedCommercial: {
      get() {
        return this.selectedMediaPlanCommercial;
      },
      set(value) {
        this.$emit('onCommercialSelect', value);
      },
    },
    commercialItems: {
      get() {
        return this.mediaPlanCommercials;
      },
      set(value) {
        this.$emit('mediaPlanCommercials', value);
      },
    },
    ...mapGetters({
      //isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      commercialVersionTypes: 'getCommercialVersionTypes',
    }),
    canCreate: function () {
      return this.$checkPermissions('spot.create');
    },
  },
  methods: {
    // Add selected commercial in table
    onCommRowSelected(items) {
      this.selectedCommercial = items[0] || null;
      this.commercialItems.forEach((el) => {
        if (el.double_spot_id) {
          if (this.selectedCommercial && el.double_spot_id === this.selectedCommercial.double_spot_id) {
            el.couple = 'couple';
          } else {
            delete el.couple;
          }
        }
      });
      if (items[0]) this.$emit('clearPocketTableSelected'); // for oneDays
    },

    // Select table row when premium position was selected
    selectTableRow(index) {
      this.$refs.selectableTable.selectRow(index);
      this.$emit('clearPocketTableSelected'); // for oneDays
    },
  },
};
</script>

<style lang="sass" src="../../assets/sass/bookingCommercialsTable.sass"></style>
